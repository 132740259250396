/* Pretendard-Light 폰트 불러오기 */


@font-face {
  font-family: 'Pretendard';
  src: url('../public/Fonts/Pretendard-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

body {
  @apply bg-gray05;
  margin: 0;
  font-family: 'Pretendard', sans-serif; /* Pretendard 폰트로 변경 */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.container {
  max-width: 480px;
  margin: 0 auto;
  overflow-x: hidden;
}

@tailwind base;
@tailwind components;
@tailwind utilities;